import { Component, Input } from '@angular/core';
import { SoftwareState, SoftwareStateItem } from '../../models/software-state';
import { InstalledVersionInfo } from '../../models/installed-version-info';

@Component({
  selector: 'app-node-software',
  templateUrl: './node-software.component.html'
})
export class NodeSoftwareComponent {
  @Input() public softwareState: SoftwareState | null = null;
  @Input() public installedVersions: InstalledVersionInfo[] | null = null;

  public findInstalledVersion(item: SoftwareStateItem): string | null {
    if (this.installedVersions && this.installedVersions.length) {
      const match = this.installedVersions.find(
        i => item.name === i.softwareName
      );
      if (match) {
        return match.reportedVersion;
      }
    }
    return null;
  }
}
