<div mat-dialog-title>
  <ui-title
    size="default"
    [title]="'bundleDeviations.title' | translate"
  ></ui-title>
</div>
<mat-dialog-content>
  @if (data.bundleDeviations) {
    <table [dataSource]="data.bundleDeviations" mat-table>
      <ng-container matColumnDef="software">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'bundleDeviations.software' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>
          {{ item.software }}
        </td>
      </ng-container>
      <ng-container matColumnDef="bundleVersion">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'bundleDeviations.bundleVersion' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>{{ item.bundleVersion }}</td>
      </ng-container>
      <ng-container matColumnDef="actualVersion">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'bundleDeviations.actualVersion' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>{{ item.actualVersion }}</td>
      </ng-container>
      <tr
        *matHeaderRowDef="['software', 'bundleVersion', 'actualVersion']"
        mat-header-row
      ></tr>
      <tr
        *matRowDef="
          let row;
          columns: ['software', 'bundleVersion', 'actualVersion']
        "
        mat-row
      ></tr>
    </table>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button type="button" (click)="close()">
    {{ 'common.close' | translate }}
  </button>
</mat-dialog-actions>
