import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  mergeMap,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import { DigitalTwinNode } from '../models/digital-twin-node';
import { DigitalTwinApiService } from '../services/digital-twin-api.service';
import { DigitalTwinNodeRelationsEnum } from '../models/digital-twin-node-relations.enum';
import { DigitalTwinNodeTypeEnum } from '../models/digital-twin-node-type.enum';
import { DigitalTwinGetContentResponse } from '../models/digital-twin-get-content-response';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ApplyBundleDialogComponent } from './apply-bundle/apply-bundle-dialog.component';
import { BundleDeviation } from '../models/bundle-deviation';
import { InstalledVersionInfo } from '../models/installed-version-info';
import { RevertDialogComponent } from './revert-dialog/revert-dialog.component';
import { BundleDeviationsDialogComponent } from './bundle-deviations-dialog/bundle-deviations-dialog.component';

@Component({
  selector: 'app-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss']
})
export class NodeComponent implements OnDestroy {
  public _node$: Observable<DigitalTwinNode>;
  public _destroy$ = new Subject<void>();
  public _reload$ = new BehaviorSubject<null>(null);
  public _nodeTypeEnum = DigitalTwinNodeTypeEnum;

  public _content$: Observable<DigitalTwinGetContentResponse | null>;
  public _bundleDeviations$: Observable<BundleDeviation[] | null>;
  public _installedVersions$: Observable<InstalledVersionInfo[] | null>;

  constructor(
    activatedRoute: ActivatedRoute,
    private _router: Router,
    private _service: DigitalTwinApiService,
    private _dialog: MatDialog,
    private _translation: TranslateService
  ) {
    this._node$ = combineLatest([activatedRoute.paramMap, this._reload$]).pipe(
      switchMap(([params]) => {
        return this._service.getNodeById(
          parseInt(params.get('id')!, 10),
          DigitalTwinNodeRelationsEnum.ParentNode |
            DigitalTwinNodeRelationsEnum.ChildNodes |
            DigitalTwinNodeRelationsEnum.LastAppliedBundleVersion
        );
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this._content$ = this._node$.pipe(
      mergeMap(node => {
        return node.nodeType === DigitalTwinNodeTypeEnum.Vehicle
          ? this._service.getNodeContentById(node.id)
          : of(null);
      }),
      takeUntil(this._destroy$)
    );

    this._bundleDeviations$ = this._node$.pipe(
      mergeMap(node => {
        return node.nodeType === DigitalTwinNodeTypeEnum.Vehicle
          ? this._service.getBundleDeviations(node.id)
          : of([]);
      }),
      takeUntil(this._destroy$)
    );

    this._installedVersions$ = this._node$.pipe(
      mergeMap(node => {
        return node.nodeType === DigitalTwinNodeTypeEnum.Vehicle
          ? this._service.getInstalledVersions(node.id)
          : of([]);
      }),
      takeUntil(this._destroy$)
    );
  }

  public showDeviationsDialog(bundleDeviations: BundleDeviation[]): void {
    this._dialog.open(BundleDeviationsDialogComponent, {
      data: { bundleDeviations },
      panelClass: 'warning'
    });
  }

  public delete(node: DigitalTwinNode): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: { message: this._translation.instant('node.deleteConfirmation') },
      panelClass: 'warning'
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed === true) {
        this._service
          .deleteNodeById(node.id)
          .subscribe(() => this._router.navigate(['/node', node.parentId]));
      }
    });
  }

  public revert(node: DigitalTwinNode, version: number): void {
    const dialogRef = this._dialog.open(RevertDialogComponent, {
      data: {
        diff$: this._service.getNodeDiff({
          a: {
            nodeId: node.id,
            version: node.version
          },
          b: {
            nodeId: node.id,
            version: version
          }
        })
      },
      panelClass: 'info'
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed === true) {
        this._service.revert(node.id, version).subscribe(() => {
          this._reload$.next(null);
        });
      }
    });
  }

  public showApplyBundleDialog(node: DigitalTwinNode): void {
    const dialogRef = this._dialog.open(ApplyBundleDialogComponent, {
      data: {
        vehicleType: node.vehicleType,
        bundleVersions$: this._service.getBundleVersionsByVehicleType(
          node.vehicleType
        )
      },
      panelClass: 'info'
    });
    dialogRef.afterClosed().subscribe(bundleVersion => {
      if (bundleVersion) {
        this._service
          .applyBundleVersions([node.id], node.vehicleType, bundleVersion)
          .subscribe(() => {
            console.log('reload');
            this._reload$.next(null);
          });
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
