import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigitalTwinDiff } from '../../models/digital-twin-diff';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-show-diff-dialog',
  templateUrl: './show-diff-dialog.component.html'
})
export class ShowDiffDialogComponent {
  public fields = ['key', 'value.oldValue', 'value.newValue'];

  constructor(
    private _dialogRef: MatDialogRef<ShowDiffDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      diff$: Observable<DigitalTwinDiff>;
      versionA: number;
      versionB: number;
    }
  ) {}

  public countKeys(value: object): number {
    return Object.keys(value).length;
  }

  public close(): void {
    this._dialogRef.close();
  }
}
