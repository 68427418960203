<ui-shell-page-title [title]="'node.add.title' | translate">
</ui-shell-page-title>
<ng-container *ngIf="_vehicleTypes$ | async as vehicleTypes">
  <ng-container *ngIf="_siteSpecNode$ | async as siteSpecNode">
    <form [formGroup]="_addForm" (ngSubmit)="create(siteSpecNode)">
      <ui-card [border]="true" [rounded]="true" [headerOnly]="false">
        <ui-card-header>
          <ui-title
            state="success"
            [title]="'node.add.title' | translate"
            subtitle="{{ 'node.add.subtitle' | translate }} {{
              siteSpecNode.name
            }}"
            [svgIcon]="true"
            icon="icon_AGV"
          ></ui-title>
        </ui-card-header>

        <mat-form-field>
          <mat-label>{{ 'node.vehicletype' | translate }}</mat-label>
          <mat-select formControlName="vehicleType">
            <mat-option *ngFor="let vehicleType of vehicleTypes" [value]="vehicleType">{{
              vehicleType
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'common.vin' | translate }}</mat-label>
          <input
            matInput
            maxlength="50"
            required
            formControlName="vin"
            tabindex="1"
          />
        </mat-form-field>

        <ui-callout
          *ngIf="_addForm.controls['vin'].hasError('vinConflict')"
          state="error"
          [title]="'node.add.vinConflict' | translate"
        >
        </ui-callout>

        <mat-form-field>
          <mat-label>{{ 'common.description' | translate }}</mat-label>
          <input
            matInput
            maxlength="100"
            formControlName="description"
            tabindex="2"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'node.add.contentCopySource' | translate }}</mat-label>
          <input
            matInput
            maxlength="100"
            formControlName="contentCopySource"
            tabindex="3"
          />
        </mat-form-field>

        <ui-callout
          *ngIf="_addForm.controls['contentCopySource'].hasError('invalidVin')"
          state="error"
          [title]="'node.add.invalidVin' | translate"
        >
        </ui-callout>

        <ui-card
          [border]="true"
          [rounded]="true"
          [headerOnly]="false"
          *ngIf="_copySource"
        >
          <ui-card-header>
            <ui-title
              [title]="'node.add.copySettingsTitle' | translate"
              subtitle="{{ 'node.add.copySettingsSubTitle' | translate }} {{
                _copySource.vin
              }}"
            ></ui-title>
          </ui-card-header>

          <mat-form-field>
            <mat-label>{{ 'node.add.cStatSet' | translate }}</mat-label>
            <mat-select formControlName="cStatSetOption">
              <mat-option [value]="optionsEnum.Clear">{{
                'enums.setFileUpdateOptions.' + optionsEnum[optionsEnum.Clear]
                  | translate
              }}</mat-option>
              <mat-option [value]="optionsEnum.UseSource">{{
                'enums.setFileUpdateOptions.' +
                  optionsEnum[optionsEnum.UseSource] | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'node.add.cStatSetDiff' | translate }}</mat-label>
            <mat-select formControlName="cStatSetDiffOption">
              <mat-option [value]="optionsEnum.Clear">{{
                'enums.setFileUpdateOptions.' + optionsEnum[optionsEnum.Clear]
                  | translate
              }}</mat-option>
              <mat-option [value]="optionsEnum.UseSource">{{
                'enums.setFileUpdateOptions.' +
                  optionsEnum[optionsEnum.UseSource] | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              {{ 'node.add.dynSet' | translate }}
            </mat-label>
            <mat-select formControlName="dynSetOptions">
              <mat-option [value]="optionsEnum.Clear">{{
                'enums.setFileUpdateOptions.' + optionsEnum[optionsEnum.Clear]
                  | translate
              }}</mat-option>
              <mat-option [value]="optionsEnum.UseSource">{{
                'enums.setFileUpdateOptions.' +
                  optionsEnum[optionsEnum.UseSource] | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ui-card>

        <div *ngIf="_errors">
          <ui-callout
            *ngFor="let error of _errors"
            state="error"
            title="{{ error }}"
          >
          </ui-callout>
        </div>

        <ui-card-footer>
          <div class="button-group">
            <button
              tabindex="4"
              type="button"
              mat-stroked-button
              color="primary"
              [disabled]="_working"
              (click)="_location.back()"
            >
              {{ 'common.cancel' | translate }}
            </button>
            <button
              tabindex="5"
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="_addForm.invalid || _working"
            >
              <span *ngIf="!_working">{{ 'common.create' | translate }}</span>
              <mat-progress-spinner
                *ngIf="_working"
                mode="indeterminate"
                color="primary"
                diameter="20"
              ></mat-progress-spinner>
            </button>
          </div>
        </ui-card-footer>
      </ui-card>
    </form>
  </ng-container>
</ng-container>
