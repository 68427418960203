<ng-container *ngIf="_node$ | async as node">
  <ui-shell-page-title [title]="'node.title' | translate">
  </ui-shell-page-title>

  <ui-card [border]="true" [rounded]="true" *ngIf="node">
    <ui-card-header>
      <ui-title
        size="small"
        state="success"
        [title]="node.name"
        [subtitle]="_nodeTypeEnum[node.nodeType]"
        [svgIcon]="true"
        [icon]="
          node.nodeType === _nodeTypeEnum.Vehicle
            ? 'icon_commissioning'
            : 'nav_management'
        "
      ></ui-title>
    </ui-card-header>
    <table>
      <tr>
        <td>{{ 'node.properties.parent' | translate }}</td>
        <td *ngIf="node.parentNode">
          <a [routerLink]="['../', node.parentNode.id]">{{
            node?.parentNode?.name
          }}</a>
        </td>
        <td *ngIf="!node.parentNode">
          None <a [routerLink]="['/']"> (back to root level list)</a>
        </td>
      </tr>
      <tr>
        <td>{{ 'node.properties.type' | translate }}</td>
        <td>
          {{ 'enums.nodeTypeEnum.' + _nodeTypeEnum[node.nodeType] | translate }}
        </td>
      </tr>
      <tr>
        <td>{{ 'node.properties.vehicleType' | translate }}</td>
        <td>
          {{ node.vehicleType }}
        </td>
      </tr>
      <tr>
        <td>{{ 'node.properties.name' | translate }}</td>
        <td>
          {{ node.name }}
        </td>
      </tr>
      <tr *ngIf="node.vin">
        <td>{{ 'node.properties.vin' | translate }}</td>
        <td>
          {{ node.vin }}
        </td>
      </tr>
      <tr *ngIf="node.lastAppliedBundleVersion">
        <td>{{ 'node.properties.lastAppliedBundleVersion' | translate }}</td>
        <td>
          {{ node.lastAppliedBundleVersion.version }}
        </td>
      </tr>
      <tr>
        <td>{{ 'node.properties.created' | translate }}</td>
        <td>
          {{ node.createdOn | date: 'medium' }} {{ 'common.by' | translate }}
          {{ node.createdBy }}
        </td>
      </tr>
      <tr>
        <td>{{ 'node.properties.modified' | translate }}</td>
        <td>
          {{ node.modifiedOn | date: 'medium' }} {{ 'common.by' | translate }}
          {{ node.modifiedBy }}
        </td>
      </tr>
    </table>
    @if (_bundleDeviations$ | async; as bundleDeviations) {
      <ng-container *ngIf="bundleDeviations.length">
        <div class="clickable">
          <ui-callout
            state="warning"
            [title]="'node.properties.bundleDeviations' | translate"
            (click)="showDeviationsDialog(bundleDeviations)"
          >
          </ui-callout>
        </div>
      </ng-container>
    }
    <ui-card-footer *ngIf="node.nodeType === _nodeTypeEnum.Vehicle">
      <div class="button-group">
        <button
          type="button"
          mat-stroked-button
          color="primary"
          (click)="showApplyBundleDialog(node)"
        >
          {{ 'node.showApplyBundleDialog.button' | translate }}
        </button>
        <button
          type="button"
          mat-stroked-button
          color="primary"
          (click)="delete(node)"
        >
          {{ 'common.delete' | translate }}
        </button>
        <button
          mat-flat-button
          color="primary"
          type="button"
          [routerLink]="['../', node.parentId, 'add']"
          [queryParams]="{ source: node.vin, vehicleType: node.vehicleType }"
        >
          {{ 'common.duplicate' | translate }}
        </button>
      </div>
    </ui-card-footer>
    <ui-card-footer
      *ngIf="node.nodeType === _nodeTypeEnum.Group && node.parentId"
    >
      <div class="button-group">
        <button
          mat-stroked-button
          *ngIf="node.parentNode && !node.parentNode.parentId"
          [routerLink]="['add']"
        >
          Add Vehicle
        </button>
      </div>
    </ui-card-footer>
  </ui-card>

  <ng-container *ngIf="node.childNodes?.length">
    <h3>{{ 'node.properties.children' | translate }}</h3>
    <ui-card
      [border]="true"
      [rounded]="true"
      *ngFor="let childNode of node.childNodes"
      [routerLink]="['../', childNode.id]"
    >
      <ui-card-header>
        <ui-title
          size="small"
          state="success"
          [title]="childNode.name"
          [subtitle]="_nodeTypeEnum[childNode.nodeType]"
          [svgIcon]="true"
          [icon]="
            childNode.nodeType === _nodeTypeEnum.Group
              ? 'nav_management'
              : 'icon_commissioning'
          "
        ></ui-title>
      </ui-card-header>
    </ui-card>
  </ng-container>

  <ng-container *ngIf="_content$ | async as content">
    <mat-tab-group animationDuration="0">
      <mat-tab
        label="{{ 'node.software.title' | translate }} ({{
          content.softwareState.software.length
        }})"
      >
        <app-node-software
          [softwareState]="content.softwareState"
          [installedVersions]="_installedVersions$ | async"
        ></app-node-software>
      </mat-tab>
      <mat-tab
        label="{{ 'node.blobs.title' | translate }} ({{
          content.blobs.length
        }})"
      >
        <app-node-blobs [blobs]="content.blobs"></app-node-blobs>
      </mat-tab>
      <mat-tab label="{{ 'node.truckDefinition.title' | translate }}">
        <app-node-truckdefinition
          [truckDefinition]="content.truckDefinition"
        ></app-node-truckdefinition>
      </mat-tab>
      <mat-tab
        label="{{ 'node.reportedParts.title' | translate }} ({{
          content.reportedParts.parts.length
        }})"
      >
        <app-node-reported-parts
          [reportedParts]="content.reportedParts"
        ></app-node-reported-parts>
      </mat-tab>
      <mat-tab
        label="{{ 'node.expectedParts.title' | translate }} ({{
          content.expectedParts.parts.length
        }})"
      >
        <app-node-expected-parts
          [expectedParts]="content.expectedParts"
        ></app-node-expected-parts>
      </mat-tab>
      <mat-tab
        label="{{ 'node.crcCodes.title' | translate }} ({{
          content.crcCodes.crc.length
        }})"
      >
        <app-node-crc-codes [crc]="content.crcCodes"></app-node-crc-codes>
      </mat-tab>
      <mat-tab
        label="{{ 'node.history.title' | translate }} ({{
          content.info.history?.length
        }})"
      >
        <app-node-history
          [history]="content.info.history"
          (revert)="revert(node, $event)"
        ></app-node-history>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-container>
