import { Component, Inject } from '@angular/core';
import { ApplyBundleDialogData } from '../../models/apply-bundle-dialog-data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-apply-bundle-dialog',
  templateUrl: './apply-bundle-dialog.component.html'
})
export class ApplyBundleDialogComponent {
  public _form: FormGroup;

  constructor(
    formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<ApplyBundleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ApplyBundleDialogData
  ) {
    this._form = formBuilder.group({
      bundleVersion: ['', [Validators.required]]
    });
  }

  public submit(): void {
    this._dialogRef.close(this._form.controls['bundleVersion'].value);
  }

  public cancel(): void {
    this._dialogRef.close(null);
  }
}
