<ng-container *ngIf="history">
  <table [dataSource]="history" mat-table>
    <ng-container matColumnDef="modifiedOn">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.history.modifiedOn' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>
        {{ item.modifiedOn | date : 'medium' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="modifiedBy">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.history.modifiedBy' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>{{ item.modifiedBy }}</td>
    </ng-container>
    <ng-container matColumnDef="version">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.history.version' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>{{ item.version }}</td>
    </ng-container>
    <ng-container matColumnDef="modificationNote">
      <th *matHeaderCellDef mat-header-cell>
        {{ 'node.history.modificationNote' | translate }}
      </th>
      <td *matCellDef="let item" mat-cell>{{ item.modificationNote }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>

      </th>
      <td *matCellDef="let item" mat-cell>
        <button mat-flat-button type="button" (click)="revert.next(item.version); $event.stopPropagation()">
          {{ 'node.history.revert' | translate }}
        </button>
      </td>
    </ng-container>
    <tr
      *matHeaderRowDef="[
        'modifiedOn',
        'modifiedBy',
        'version',
        'modificationNote',
        'actions'
      ]"
      mat-header-row
    ></tr>
    <tr
      *matRowDef="
        let row;
        columns: [
          'modifiedOn',
          'modifiedBy',
          'version',
          'modificationNote',
          'actions'
        ]
      "
      mat-row
      (click)="showDiff(row, history)"
      class="clickable"
    ></tr>
  </table>
</ng-container>
