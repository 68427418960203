<div mat-dialog-title>
  <ui-title
    size="default"
    [title]="'revert.title' | translate"
    [subtitle]="'revert.subtitle' | translate"
  ></ui-title>
</div>
<mat-dialog-content>
  @if (data.diff$ | async; as diff) {
    <app-node-diff
      [diff]="diff"
      [hideProperties]="true"
      [hideReportedParts]="true"
    />
  } @else {
    <p>{{ 'common.loading' | translate }} ...</p>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button type="button" (click)="cancel()">
    {{ 'common.cancel' | translate }}
  </button>
  <button mat-flat-button type="button" (click)="confirm()">
    {{ 'revert.confirm' | translate }}
  </button>
</mat-dialog-actions>
