import { Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { DigitalTwinNode } from '../../models/digital-twin-node';
import {
  catchError,
  delay,
  filter,
  finalize,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DigitalTwinApiService } from '../../services/digital-twin-api.service';
import { DigitalTwinNodeRelationsEnum } from '../../models/digital-twin-node-relations.enum';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { SetFileUpdateOptionsEnum } from '../../models/set-file-update-options-enum';

@Component({
  selector: 'app-add-node',
  templateUrl: './add-node.component.html'
})
export class AddNodeComponent implements OnDestroy {
  public _destroy$ = new Subject<void>();
  public _siteSpecNode$: Observable<DigitalTwinNode | null>;
  public _vehicleTypes$: Observable<string[]>;
  public _copySource: DigitalTwinNode | null = null;
  public _addForm: FormGroup;
  public _errors: string[] | null = null;
  public optionsEnum = SetFileUpdateOptionsEnum;
  private _debounceTime = 300;
  public _working = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    formBuilder: FormBuilder,
    private _router: Router,
    private _service: DigitalTwinApiService,
    public _location: Location
  ) {
    this._addForm = formBuilder.group({
      vehicleType: ['', [Validators.required]],
      vin: ['', [Validators.required], [this._vinUniquenessValidator()]],
      description: [''],
      contentCopySource: ['', [], [this._copySourceValidator()]],
      cStatSetOption: [SetFileUpdateOptionsEnum.Clear],
      cStatSetDiffOption: [SetFileUpdateOptionsEnum.Clear],
      dynSetOptions: [SetFileUpdateOptionsEnum.Clear]
    });

    this._siteSpecNode$ = this._activatedRoute.paramMap.pipe(
      switchMap(params =>
        this._service.getNodeById(
          parseInt(params.get('id')!, 10),
          DigitalTwinNodeRelationsEnum.ParentNode
        )
      ),
      filter(node => !!node.parentNode && !node.parentNode.parentId),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this._vehicleTypes$ = this._service.getVehicleTypes();

    this._activatedRoute.queryParams
      .pipe(takeUntil(this._destroy$))
      .subscribe(queryParams => {
        if (queryParams['source']) {
          this._addForm.controls['contentCopySource'].setValue(
            queryParams['source']
          );
        }
        if (queryParams['vehicleType']) {
          this._addForm.controls['vehicleType'].setValue(
            queryParams['vehicleType']
          );
        }
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public getNodeByVin(vin: string): Observable<DigitalTwinNode | null> {
    if (!vin || vin.length < 4) {
      return of(null);
    }
    return this._service
      .getNodeByVin(vin, DigitalTwinNodeRelationsEnum.None)
      .pipe(catchError(() => of(null)));
  }

  private _vinUniquenessValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value.length < 4) {
        return of(null);
      }
      return of(control.value).pipe(
        delay(this._debounceTime),
        switchMap(() => this.getNodeByVin(control.value)),
        map(res => {
          return res ? { vinConflict: true } : null;
        })
      );
    };
  }

  private _copySourceValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value.length < 4) {
        return of(null);
      }
      return of(control.value).pipe(
        delay(this._debounceTime),
        switchMap(() => this.getNodeByVin(control.value)),
        map(res => {
          this._copySource = res;
          return res ? null : { invalidVin: true };
        })
      );
    };
  }

  public create(siteSpecNode: DigitalTwinNode): void {
    if (this._addForm.invalid) {
      return;
    }
    this._working = true;
    this._service
      .addVehicle({ ...this._addForm.value, parentId: siteSpecNode.id })
      .pipe(finalize(() => (this._working = false)))
      .subscribe(result => {
        if (result.success) {
          this._router.navigate(['/node', result.savedNode.id]);
        } else {
          this._errors = result.validationMessages;
        }
      });
  }
}
