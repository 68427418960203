import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DigitalTwinDiff } from '../../models/digital-twin-diff';

@Component({
  selector: 'app-revert-dialog',
  templateUrl: './revert-dialog.component.html'
})
export class RevertDialogComponent {
  constructor(
    private _dialogRef: MatDialogRef<RevertDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      diff$: Observable<DigitalTwinDiff>;
    }
  ) {}

  public cancel(): void {
    this._dialogRef.close();
  }

  public confirm(): void {
    this._dialogRef.close(true);
  }
}
