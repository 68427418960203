import { Component, Input } from '@angular/core';
import { DigitalTwinDiff } from '../../models/digital-twin-diff';

@Component({
  selector: 'app-node-diff',
  templateUrl: './node-diff.component.html'
})
export class NodeDiffComponent {
  public fields = ['key', 'value.oldValue', 'value.newValue'];

  @Input() public diff: DigitalTwinDiff | null = null;
  @Input() public hideProperties = false;
  @Input() public hideReportedParts = false;

  public countKeys(value: object): number {
    return Object.keys(value).length;
  }
}
