<mat-tab-group animationDuration="0" *ngIf="diff">
  <mat-tab *ngIf="!hideProperties" [label]="'diff.properties' | translate">
    <table [dataSource]="diff.node | keyvalue" mat-table>
      <ng-container matColumnDef="key">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'diff.property' | translate }}
        </th>
        <td *matCellDef="let item" class="operation" mat-cell>
          {{ item.key }}
        </td>
      </ng-container>
      <ng-container matColumnDef="value.oldValue">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'diff.oldValue' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>{{ item.value.oldValue }}</td>
      </ng-container>
      <ng-container matColumnDef="value.newValue">
        <th *matHeaderCellDef mat-header-cell>
          {{ 'diff.newValue' | translate }}
        </th>
        <td *matCellDef="let item" mat-cell>{{ item.value.newValue }}</td>
      </ng-container>
      <tr
        *matHeaderRowDef="['key', 'value.oldValue', 'value.newValue']"
        mat-header-row
      ></tr>
      <tr
        *matRowDef="
          let row;
          columns: ['key', 'value.oldValue', 'value.newValue']
        "
        class="{{ row.value.type }}"
        mat-row
      ></tr>
    </table>
  </mat-tab>
  @if (countKeys(diff.softwareState); as keyCount) {
    <mat-tab label="{{ 'diff.software' | translate }} ({{ keyCount }})">
      <table [dataSource]="diff.softwareState | keyvalue" mat-table>
        <ng-container matColumnDef="key">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.software' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>{{ item.key }}</td>
        </ng-container>
        <ng-container matColumnDef="value.type">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.operation' | translate }}
          </th>
          <td *matCellDef="let item" class="operation" mat-cell>
            {{ item.value.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value.oldVersion">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.oldVersion' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>
            {{ item.value.oldVersion }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value.newVersion">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.newVersion' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>
            {{ item.value.newVersion }}
          </td>
        </ng-container>
        <tr
          *matHeaderRowDef="[
            'key',
            'value.type',
            'value.oldVersion',
            'value.newVersion'
          ]"
          mat-header-row
        ></tr>
        <tr
          *matRowDef="
            let row;
            columns: [
              'key',
              'value.type',
              'value.oldVersion',
              'value.newVersion'
            ]
          "
          class="{{ row.value.type }}"
          mat-row
        ></tr>
      </table>
    </mat-tab>
  }
  @if (countKeys(diff.blobs); as keyCount) {
    <mat-tab label="{{ 'diff.blobs' | translate }} ({{ keyCount }})">
      <table [dataSource]="diff.blobs | keyvalue" mat-table>
        <ng-container matColumnDef="value.type">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.operation' | translate }}
          </th>
          <td *matCellDef="let item" class="operation" mat-cell>
            {{ item.value.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="key">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.filename' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>{{ item.key }}</td>
        </ng-container>
        <tr *matHeaderRowDef="['key', 'value.type']" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: ['key', 'value.type']"
          class="{{ row.value.type }}"
          mat-row
        ></tr>
      </table>
    </mat-tab>
  }
  @if (countKeys(diff.reportedParts); as keyCount) {
    <mat-tab *ngIf="!hideReportedParts"
      label="{{ 'diff.reportedParts' | translate }} ({{ keyCount }})"
    >
      <table [dataSource]="diff.reportedParts | keyvalue" mat-table>
        <ng-container matColumnDef="key">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.name' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>{{ item.key }}</td>
        </ng-container>
        <ng-container matColumnDef="value.type">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.operation' | translate }}
          </th>
          <td *matCellDef="let item" class="operation" mat-cell>
            {{ item.value.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value.newPart.type">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.type' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>
            {{ item.value.newPart?.type ?? item.value.oldPart?.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value.newPart.manufacturer">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.manufacturer' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>
            {{
              item.value.newPart?.manufacturer ??
                item.value.oldPart?.manufacturer
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value.newPart.state">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.state' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>
            {{ item.value.newPart?.state ?? item.value.oldPart?.state }}
          </td>
        </ng-container>
        <tr
          *matHeaderRowDef="[
            'value.type',
            'key',
            'value.newPart.type',
            'value.newPart.manufacturer',
            'value.newPart.state'
          ]"
          mat-header-row
        ></tr>
        <tr
          *matRowDef="
            let row;
            columns: [
              'value.type',
              'key',
              'value.newPart.type',
              'value.newPart.manufacturer',
              'value.newPart.state'
            ]
          "
          class="{{ row.value.type }}"
          mat-row
        ></tr>
      </table>
    </mat-tab>
  }
  @if (countKeys(diff.expectedParts); as keyCount) {
    <mat-tab
      label="{{ 'diff.expectedParts' | translate }} ({{ keyCount }})"
    >
      <table [dataSource]="diff.expectedParts | keyvalue" mat-table>
        <ng-container matColumnDef="key">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.name' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>{{ item.key }}</td>
        </ng-container>
        <ng-container matColumnDef="value.type">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.operation' | translate }}
          </th>
          <td *matCellDef="let item" class="operation" mat-cell>
            {{ item.value.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value.newPart.type">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.type' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>
            {{ item.value.newPart?.type ?? item.value.oldPart?.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value.newPart.isDetectable">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.isDetectable' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>
            {{
              item.value.newPart?.isDetectable ??
                item.value.oldPart?.isDetectable
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="value.newPart.detectableAsType">
          <th *matHeaderCellDef mat-header-cell>
            {{ 'diff.detectableAsType' | translate }}
          </th>
          <td *matCellDef="let item" mat-cell>
            {{
              item.value.newPart?.detectableAsType ??
                item.value.oldPart?.detectableAsType
            }}
          </td>
        </ng-container>
        <tr
          *matHeaderRowDef="[
            'value.type',
            'key',
            'value.newPart.type',
            'value.newPart.isDetectable',
            'value.newPart.detectableAsType'
          ]"
          mat-header-row
        ></tr>
        <tr
          *matRowDef="
            let row;
            columns: [
              'value.type',
              'key',
              'value.newPart.type',
              'value.newPart.isDetectable',
              'value.newPart.detectableAsType'
            ]
          "
          class="{{ row.value.type }}"
          mat-row
        ></tr>
      </table>
    </mat-tab>
  }
</mat-tab-group>
