import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { RouterModule } from '@angular/router';
import { CoreModule, GenericTableModule, IconsModule } from '@onyx/core';
import { NAVIGATION_CONFIG, ShellModule } from '@onyx/shell';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RequestOverviewComponent } from './request-overview/request-overview.component';
import { environment } from '../environments/environment';
import { RequestOverviewSidepaneComponent } from './request-overview/sidepane/request-overview-sidepane.component';
import { NodeComponent } from './node/node.component';
import { TranslationsModule } from './shared/modules/translations/translations.module';
import { TranslatedNavigationService } from './shared/modules/translations/onyx/translated-navigation.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { NodeBlobsComponent } from './node/blobs/node-blobs.component';
import { NodeCrcCodesComponent } from './node/crc-codes/node-crc-codes.component';
import { NodeExpectedPartsComponent } from './node/expectedparts/node-expected-parts.component';
import { NodeReportedPartsComponent } from './node/reportedparts/node-reported-parts.component';
import { NodeSoftwareComponent } from './node/software/node-software.component';
import { NodeTruckDefinitionComponent } from './node/truckdefinition/node-truckdefinition.component';
import { NodeHistoryComponent } from './node/history/node-history.component';
import { AddNodeComponent } from './node/add/add-node.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  AuthInterceptor,
  AuthModule,
  LogLevel
} from 'angular-auth-oidc-client';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';
import { ApplyBundleDialogComponent } from './node/apply-bundle/apply-bundle-dialog.component';
import { NodeDiffComponent } from './node/node-diff/node-diff.component';
import { RevertDialogComponent } from './node/revert-dialog/revert-dialog.component';
import { ShowDiffDialogComponent } from './node/show-diff-dialog/show-diff-dialog.component';
import { BundleDeviationsDialogComponent } from './node/bundle-deviations-dialog/bundle-deviations-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ApplyBundleDialogComponent,
    HomeComponent,
    RequestOverviewComponent,
    RequestOverviewSidepaneComponent,
    NodeComponent,
    NodeBlobsComponent,
    NodeCrcCodesComponent,
    NodeExpectedPartsComponent,
    NodeReportedPartsComponent,
    NodeSoftwareComponent,
    NodeTruckDefinitionComponent,
    NodeHistoryComponent,
    AddNodeComponent,
    ConfirmationDialogComponent,
    UnauthorizedPageComponent,
    NodeDiffComponent,
    RevertDialogComponent,
    ShowDiffDialogComponent,
    BundleDeviationsDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShellModule.forRoot(
      { navigationItems: [], extendedNavigationItems: [] },
      {
        defaultTitle: 'Digital Twin Manager'
      }
    ), // not using forRoot because we are manually injecting NAVIGATION_CONFIG
    RouterModule.forRoot([]),
    FormsModule,
    ReactiveFormsModule,
    TranslationsModule,
    MatIcon,
    MatButton,
    CoreModule,
    IconsModule,
    MatSlideToggle,
    GenericTableModule,
    MatDialogModule,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    AuthModule.forRoot({
      config: {
        authority: environment.identity.authority,
        authWellknownEndpointUrl: environment.identity.authWellknownEndpointUrl,
        clientId: environment.identity.clientId,
        redirectUrl: environment.identity.redirectUrl,
        postLogoutRedirectUri: environment.identity.redirectUrl,
        logLevel: LogLevel.Warn,
        secureRoutes: environment.identity.securedEndpoints,
        responseType: 'code',
        useRefreshToken: true,
        silentRenew: true,
        scope: environment.identity.scopes,
        ignoreNonceAfterRefresh: true,
        renewUserInfoAfterTokenRenew: true,
        autoUserInfo: false,
        issValidationOff: true,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        customParamsAuthRequest: {
          prompt: 'select_account' // login, consent
        }
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    {
      provide: NAVIGATION_CONFIG,
      useFactory: (service: TranslatedNavigationService) =>
        service.getTranslatedNavigationConfig(),
      deps: [TranslatedNavigationService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class AppModule {}
