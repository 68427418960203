import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BundleDeviation } from '../../models/bundle-deviation';

@Component({
  selector: 'app-bundle-deviations-dialog',
  templateUrl: './bundle-deviations-dialog.component.html'
})
export class BundleDeviationsDialogComponent {
  public fields = ['key', 'value.oldValue', 'value.newValue'];

  constructor(
    private _dialogRef: MatDialogRef<BundleDeviationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      bundleDeviations: BundleDeviation[];
    }
  ) {}

  public close(): void {
    this._dialogRef.close();
  }
}
